import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  th: {
    translation: {
      register_and_earn: 'ลงทะเบียนผู้เข้าชมงาน',
      discount_coupon: 'รับของชำร่วยสุดพิเศษจากชุมชนในจังหวัด',
      register: 'ลงทะเบียน',
      gender: 'เพศ',
      date_of_birth: 'วันเกิด (ค.ศ.)',
      email: 'อีเมล',
      country: 'ประเทศ',
      first_name: 'ชื่อจริง',
      last_name: 'นามสกุล',
      phone_number: 'เบอร์โทรศัพท์',
      error_first_name: 'กรุณาระบุชื่อจริง',
      error_last_name: 'กรุณาระบุนามสกุล',
      error_gender: 'กรุณาระบุเพศ',
      error_date_of_birth: 'กรุณาเลือกวันเกิด',
      error_email: 'กรุณาระบุอีเมล',
      error_country: 'กรุณาระบุประเทศ',
      error_phone_number: 'กรุณาระบุเบอร์โทรศัพท์',
      invalid_email: 'อีเมลไม่ถูกต้อง',
      term_and_condition:
        'เราเก็บข้อมูลเพื่อใช้เป็นสถิตินับยอดผู้เข้าชมงานเท่านั้น',
      male: 'ชาย',
      female: 'หญิง',
      lgbtq: 'LGBTQ+',
      registration_successful: 'ลงทะเบียนเสร็จสิ้น',
      thank_you_for_registering:
        'ขอขอบคุณที่ลงทะเบียนเข้าชมงาน กรุณาแสดงหน้านี้ให้กับเจ้าหน้าที่เพื่อรับของชำร่วยสุดพิเศษ',
      explore_nearby: 'เปิดที่เที่ยวรอบตัว',
      facebook_page: 'Facebook Page',
      province: 'จังหวัดภูมิลำเนา',
    },
  },
  en: {
    translation: {
      register_and_earn: 'Register for the event',
      discount_coupon: 'Receive special gifts from the local',
      register: 'Register',
      gender: 'Gender',
      date_of_birth: 'Date of Birth',
      email: 'Email',
      country: 'Country',
      first_name: 'First Name',
      last_name: 'Last Name',
      phone_number: 'Phone Number',
      error_first_name: 'First Name is required',
      error_last_name: 'Last Name is required',
      error_gender: 'Please select your gender',
      error_date_of_birth: 'Please select your date of birth',
      error_email: 'Email is required',
      error_country: 'Country is required',
      error_phone_number: 'Phone number is required',
      invalid_email: 'Invalid email address',
      term_and_condition:
        'We collect your information only for statistical purposes. We will not share your information with anyone else.',
      male: 'Male',
      female: 'Female',
      lgbtq: 'LGBTQ+',
      registration_successful: 'Registration Successful',
      thank_you_for_registering:
        'Thank you for registering. Please show this page to our staff to claim your discount coupon.',
      explore_nearby: 'Explore Nearby',
      facebook_page: 'Facebook Page',
      province: 'Province',
    },
  },
  zh: {
    translation: {
      register_and_earn: '活动登记',
      discount_coupon: '领取社区特别赠品',
      register: '注册',
      gender: '性别',
      date_of_birth: '生日',
      email: '邮箱',
      country: '国家',
      first_name: '名字',
      last_name: '姓氏',
      phone_number: '手机号码',
      error_first_name: '名字是必填项',
      error_last_name: '姓氏是必填项',
      error_gender: '请选择您的性别',
      error_date_of_birth: '请选择您的出生日期',
      error_email: '电子邮件是必填项',
      error_country: '国家是必填项',
      error_phone_number: '请输入手机号码',
      invalid_email: '无效的电子邮件地址',
      term_and_condition:
        '我们收集您的信息仅用于统计目的。我们不会与任何人分享您的信息。',
      male: '男',
      female: '女',
      lgbtq: 'LGBTQ+',
      registration_successful: '注册成功',
      thank_you_for_registering:
        '感谢您注册。请将此页面显示给我们的工作人员，以获得您的折扣券。',
      explore_nearby: '探索附近',
      facebook_page: 'Facebook页面',
      province: '省份',
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'th',
  fallbackLng: 'th',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
