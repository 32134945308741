import { useTranslation } from 'react-i18next'

export default function LangSwitcher() {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  return (
    <>
      <button
        type='button'
        onClick={() =>
          i18n.changeLanguage(currentLanguage === 'th' ? 'en' : 'th')
        }
        className='bg-white w-12 h-12 rounded-lg absolute top-0 right-4 lg:top-4 lg:right-4 flex items-center justify-center border border-tnd-gray-400'>
        {currentLanguage === 'th' ? (
          <img
            src={`/img/flag-en.png`}
            alt='Switch to English'
            className='w-8 h-8'
          />
        ) : (
          <img
            src={`/img/flag-th.png`}
            alt='Switch to Thai'
            className='w-8 h-8'
          />
        )}
      </button>
    </>
  )
}
