import { isMobileOnly } from 'react-device-detect'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import './i18n'
import reportWebVitals from './reportWebVitals'
import SuccessPage from './SuccessPage'

import './fonts.css'
import './index.css'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/success',
    element: <SuccessPage />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <RouterProvider router={router} />
    <Toaster
      toastOptions={{
        style: {
          border: '2px solid #004aad',
          padding: '16px',
        },
        duration: 4000,
        position: isMobileOnly ? 'top-center' : 'top-right',
      }}
    />
  </>
)

reportWebVitals()
