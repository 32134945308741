import { useTranslation } from 'react-i18next'
import LangSwitcher from './components/LangSwitcher'

function SuccessPage() {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language

  const handleButtonClick = (url: string) => {
    if (!url) return
    window.location.href = url
  }

  return (
    <div className='form-wrapper py-12 min-h-screen form-bg'>
      <div className='container max-w-xl lg:max-w-4xl mx-auto relative'>
        <LangSwitcher />
        <img
          src='/img/icon-check.png'
          className='mx-auto max-w-[200px] mb-6'
          alt='Success'
        />
        <h1 className='text-2xl text-center mb-3'>
          {t('registration_successful')}
        </h1>
        <p className='text-center mx-auto max-w-lg mb-8'>
          {t('thank_you_for_registering')}
        </p>
        <div className='flex flex-row items-center justify-center gap-x-2 mb-4'>
          <button
            type='button'
            className='btn sm btn-primary'
            onClick={() =>
              handleButtonClick(
                `https://www.tripniceday.com/${currentLanguage}/discover?center=${process.env.REACT_APP_DISCOVER_CENTER}`
              )
            }>
            {t('explore_nearby')}
          </button>
          <button
            type='button'
            className='btn sm'
            onClick={() =>
              handleButtonClick(
                'https://www.facebook.com/amazingfoodfestival2024/'
              )
            }>
            {t('facebook_page')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SuccessPage
