import axios from 'axios'
import { Thai } from 'flatpickr/dist/l10n/th'
import 'flatpickr/dist/themes/airbnb.css'
import React from 'react'
import Flatpickr from 'react-flatpickr'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import LangSwitcher from './components/LangSwitcher'

type FormInputs = {
  firstName: string
  lastName: string
  gender: string
  phoneNumber: string
  province: string
}

type Country = {
  slug: string
  name: string
}

function App() {
  const [dateOfBirth, setDateOfBirth] = React.useState<Date>()
  const [isLoading, setLoad] = React.useState(true)
  const [provinces, setProvinces] = React.useState<Country[]>([])

  const { t } = useTranslation()
  const navigate = useNavigate()

  React.useEffect(() => {
    // fetch internal json file
    fetch('lib/provinces.json')
      .then((response) => response.json())
      .then((data) => {
        setProvinces(data)
        setLoad(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    reset,
  } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      gender: '',
      phoneNumber: '',
      province: '',
    },
  })

  watch()

  const handleSetDateOfBirth = (dates: Date[]) => {
    if (dates) setDateOfBirth(dates[0])
  }

  const submitForm = async (data: FormInputs) => {
    if (!dateOfBirth) {
      toast.error('กรุณาเลือกวันเกิดของคุณ')
      return false
    }
    try {
      setLoad(true)
      const formData = {
        ...data,
        dateOfBirth: dateOfBirth.toISOString(),
        campaignId: process.env.REACT_APP_CAMPAIGN_ID,
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendee`,
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      if (response.data.status !== 200) {
        setLoad(false)
        toast.error(response.data.payload, { position: 'top-right' })
        return false
      }
      toast.success('ลงทะเบียนเรียบร้อยแล้ว')
      reset()
      setDateOfBirth(undefined)
      setTimeout(() => navigate('/success'), 2000)
    } catch (err: any) {
      setLoad(false)
      toast.error('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง', {
        position: 'top-right',
      })
    }
  }

  // const isAllValid =
  const isAllFieldsFilled =
    getValues().firstName &&
    getValues().lastName &&
    getValues().gender &&
    getValues().phoneNumber &&
    getValues().province &&
    dateOfBirth

  return (
    <div className='form-wrapper py-6 min-h-screen form-bg'>
      <div className='container max-w-xl lg:max-w-4xl mx-auto relative'>
        <LangSwitcher />
        {/* logo */}
        <div className='flex flex-row items-center justify-center gap-x-2 mb-4 lg:mb-6'>
          <img
            src={'/img/tat-circle-logo.png'}
            className='w-20 h-20 lg:w-32 lg:h-32'
            alt='TAT'
          />
          <img
            src={'/img/amazing-circle-logo.png'}
            className='w-20 h-20 lg:w-32 lg:h-32'
            alt='Amazing Thailand'
          />
        </div>
        {/* title */}
        <h1 className='text-lg lg:text-2xl text-center lg:leading-normal font-normal mb-6 lg:mb-10'>
          {t('register_and_earn')}
          <br />
          {t('discount_coupon')}
        </h1>
        {/* form */}
        <form
          className='relative bg-white max-w-full p-8 rounded-lg shadow-lg border-tnd-gray-200 border mb-8'
          onSubmit={handleSubmit(submitForm)}>
          {isLoading ? (
            <div className='bg-white/80 preloader w-full h-full flex items-center justify-center z-50 absolute inset-0'>
              <PulseLoader color={'#004aad'} loading={isLoading} size={24} />
            </div>
          ) : null}
          <div className='lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-6'>
            <div className='group mb-6 lg:mb-0'>
              <label className='label'>
                {t('first_name')} <span className='req'>*</span>
              </label>
              <input
                type='text'
                autoComplete='off'
                autoCorrect='off'
                className='input '
                {...register('firstName', {
                  required: {
                    value: true,
                    message: t('error_first_name'),
                  },
                })}
              />
              {errors.firstName && (
                <p className='text-red-500 mt-1 text-sm'>
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div className='group mb-6 lg:mb-0'>
              <label className='label'>
                {t('last_name')} <span className='req'>*</span>
              </label>
              <input
                type='text'
                className='input'
                autoComplete='off'
                autoCorrect='off'
                {...register('lastName', {
                  required: {
                    value: true,
                    message: t('error_last_name'),
                  },
                })}
              />
              {errors.lastName && (
                <p className='text-red-500 mt-1 text-sm'>
                  {errors.lastName.message}
                </p>
              )}
            </div>
            <div className='group mb-6 lg:mb-0'>
              <label className='label'>
                {t('gender')} <span className='req'>*</span>
              </label>
              <select
                className='select'
                {...register('gender', {
                  required: {
                    value: true,
                    message: t('error_gender'),
                  },
                })}>
                <option value='' disabled></option>
                <option value='male'>{t('male')}</option>
                <option value='female'>{t('female')}</option>
                <option value='lgbtq'>{t('lgbtq')}</option>
              </select>
              {errors.gender && (
                <p className='text-red-500 mt-1 text-sm'>
                  {errors.gender.message}
                </p>
              )}
            </div>
            <div className='group mb-6 lg:mb-0'>
              <label className='label'>
                {t('date_of_birth')} <span className='req'>*</span>
              </label>
              <Flatpickr
                className='input date-picker'
                value={dateOfBirth}
                options={{
                  // max select: 18 years old
                  maxDate: new Date(
                    Date.now() - 18 * 365 * 24 * 60 * 60 * 1000
                  ),
                  locale: Thai,
                  dateFormat: 'd/m/Y',
                  altFormat: 'Y-m-d',
                  allowInput: true,
                  disableMobile: true,
                }}
                onChange={handleSetDateOfBirth}
              />
            </div>
            <div className='group mb-6 lg:mb-0'>
              <label className='label'>
                {t('phone_number')} <span className='req'>*</span>
              </label>
              <input
                type='text'
                autoComplete='off'
                autoCorrect='off'
                className='input'
                {...register('phoneNumber', {
                  required: {
                    value: true,
                    message: t('error_phone_number'),
                  },
                })}
              />
              {errors.phoneNumber && (
                <p className='text-red-500 mt-1 text-sm'>
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
            <div className='group mb-6 lg:mb-0'>
              <label className='label'>
                {t('province')} <span className='req'>*</span>
              </label>
              <select
                className='select'
                {...register('province', {
                  required: {
                    value: true,
                    message: t('error_province'),
                  },
                })}>
                <option value='' disabled></option>
                {provinces.map((province) => (
                  <option
                    value={province.name}
                    key={province.slug}
                    disabled={province.slug === ''}>
                    {province.name}
                  </option>
                ))}
              </select>
              {errors.province && (
                <p className='text-red-500 mt-1 text-sm'>
                  {errors.province.message}
                </p>
              )}
            </div>
            <div className='group col-span-2 flex flex-row items-center justify-center gap-x-2'>
              <button
                className='btn btn-primary w-full lg:w-auto'
                disabled={!isAllFieldsFilled}>
                {t('register')}
              </button>
            </div>
          </div>
        </form>
        <p className='text-xs text-center leading-relaxed lg:leading-normal text-tnd-gray-700'>
          {t('term_and_condition')}
        </p>
      </div>
    </div>
  )
}

export default App
